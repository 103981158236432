import React from "react";
import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Page,
  Inject,
  Resize,
  Edit,
  Sort,
  CommandColumn,
  Filter,
  Toolbar,
  ExcelExport,
  ForeignKey,
} from "@syncfusion/ej2-react-grids";
import { auth, cicassService } from "../../../../_services";
import { getValue } from "@syncfusion/ej2-base";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import { withTranslation } from "react-i18next";

const editSettings = {
  allowEditing: true,
  allowAdding: true,
  allowDeleting: true,
  mode: "Dialog",
  dialog: { enableResize: true },
  showDeleteConfirmDialog: true,
};
const editSettingsReduced = {
  allowEditing: false,
  allowAdding: false,
  allowDeleting: false,
  mode: "Dialog",
  dialog: { enableResize: true },
  showDeleteConfirmDialog: true,
};

const filterSettings = { type: "CheckBox" };
const commands = [
  { type: "Edit", buttonOption: { iconCss: "e-icons e-edit", cssClass: "e-flat" } },
  { type: "Delete", buttonOption: { iconCss: "e-icons e-delete", cssClass: "e-flat" } },
  { type: "Save", buttonOption: { iconCss: "e-icons e-update", cssClass: "e-flat" } },
  { type: "Cancel", buttonOption: { iconCss: "e-icons e-cancel-icon", cssClass: "e-flat" } },
  { type: "OpenGoogleSheet", buttonOption: { iconCss: 'e-icons e-CSV_Export', cssClass: 'e-flat' } },
];
const commandsReduced = [
  { type: "OpenGoogleSheet", buttonOption: { iconCss: 'e-icons e-CSV_Export', cssClass: 'e-flat' } },
];

const toolbarOptions = ["Search", "Add", "ExcelExport", "Print", {
  text: "Refresh",
  tooltipText: "Refresh",
  prefixIcon: "e-MT_reload",
  id: "forceRefresh",
},];

const apiUrl = "anatruck";

class AnaTruckPage extends React.Component {
  _isMounted = false;
  grid = null;
  
  constructor(props) {
    super(props);
    this.position = { X: "Right", Y: "Bottom" };
    this.state = {
      myData: [],
      fuelTypeData: [{ id: 'BENZINA', name: 'Benzina' },
        { id: 'DIESEL', name: 'Diesel' },],
      isCicass: false,
      isAdminOrManager: false,
      visibleOnGrid: false,
      toastInfo: {
        title: "",
        content: "Operation completed successfully",
        cssClass: "e-toast-success",
        icon: "e-success toast-icons",
      },
    };
    this.validationRules = { required: true };
    this.actionComplete = this.actionComplete.bind(this);
    this.actionBegin = this.actionBegin.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    const myRole = auth.getUserRole();
    const myAgencyId = auth.getAgencyId();
    const { data } = await cicassService.getAll(apiUrl);
    if (this._isMounted) {
      this.setState({ myData: data ,
        isCicass: myRole == "SuperUser" ? true : false,
        isAdminOrManager: myRole == "Admin" || myRole == "Manager" ? true : false});
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toolbarClick(args) {
    if (args.item.id === "gridcomp_excelexport") {
      this.gridInstance.excelExport();
    } 
    if (args.item.id === "forceRefresh") {
      this.refreshGrid();
    }
  }

  actionBegin(state) {
    if (state.requestType === "beginEdit" || state.requestType === "add") {
      if (state.requestType == "add") {
        state.id = -1;
        state.data.chassisNumber = "";
        state.data.viewOrder = 1;
        state.data.registrationYear = 1990;
        state.data.mileage = 0;
        state.data.fuelCapacity = 0;
        state.data.vehicleType= "";
        state.data.brand= "";
        state.data.model= "";
        state.data.fuelType = "";
        state.data.anaAgencyId = auth.getAgencyId();
      }
      this.setState({ visibleOnGrid: true });
    }
  }

  async refreshGrid() {
    await cicassService
      .getAll(apiUrl)
      .then((response) => {
        this.setState({ myData: response.data });
      })
      .catch((error) => {
        console.error("Error refreshing grid: ", error);
      });
  }

  setToastInfo(title, errorMsg) {
    let { toastInfo } = this.state;
    switch (title) {
      case "Warning":
        toastInfo.title = "Warning";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-warning";
        toastInfo.icon = "e-warning toast-icons";
        break;
      case "Success":
        toastInfo.title = "Success";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-success";
        toastInfo.icon = "e-success toast-icons";
        break;
      case "Error":
        toastInfo.title = "Error";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-danger";
        toastInfo.icon = "e-error toast-icons";
        break;
      case "Information":
        toastInfo.title = "Information";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-info";
        toastInfo.icon = "e-info toast-icons";
        break;
    }
    this.setState({ toastInfo });
    this.toastObj.show();
    return;
  }

  actionComplete(state) {
    if (state.requestType === "add" || state.requestType === "beginEdit") {
      state.dialog.enableResize = true;
      //state.dialog.element.style.maxHeight = 900;
      state.dialog.width = 600;
      state.dialog.height = 800;
    }
    if (state.action === "add") {
      state.data.id = -1;
      state.data.createdAt = new Date();
      cicassService
        .saveOp(apiUrl, state.data)
        .then(() => {
          this.setToastInfo("Success", this.props.t("toastSuccess"));
        })
        .catch((err) => {
          console.error("Error on Add: ", err);
          this.setToastInfo("Error", "I: " + err.message);
        })
        .finally(() => {
          this.refreshGrid();
        });
    }
    if (state.action === "edit") {
      cicassService
        .saveOp(apiUrl, state.data)
        .then(() => {
          this.setToastInfo("Success", this.props.t("toastSuccess"));
        })
        .catch((err) => {
          console.error("Error on Edit: ", err);
          this.setToastInfo("Error", "U: " + err.message);
        })
        .finally(() => {
          this.refreshGrid();
        });
    }
    if (state.requestType === "delete") {
      cicassService
        .deleteOp(apiUrl, state.data[0].id)
        .then(() => {
          this.setToastInfo("Success", this.props.t("toastSuccess"));
        })
        .catch((err) => {
          console.error("Error on Delete: ", err);
          this.setToastInfo("Error", "D: " + err.message);
        })
        .finally(() => {
          this.refreshGrid();
        });
    }
    this.setState({ visibleOnGrid: false });
  }

  editTemplateLMD(args) {
    const { t } = this.props;
    return (
      <DatePickerComponent
        value={getValue("lastMaintenanceDate", args)}
        id="lastMaintenanceDate"
        placeholder={t("last_maintenance_date")}
        floatLabelType="Always"
        format="dd/MM/yyyy"
        locale="it"
      />
    );
  }
  editTemplateNMD(args) {
    const { t } = this.props;
    return (
      <DatePickerComponent
        value={getValue("nextMaintenanceDate", args)}
        id="nextMaintenanceDate"
        placeholder={t("next_maintenance_date")}
        floatLabelType="Always"
        format="dd/MM/yyyy"
        locale="it"
      />
    );
  }

  editTemplateIED(args) {
    const { t } = this.props;
    return (
      <DatePickerComponent
        value={getValue("insuranceExpiryDate", args)}
        id="insuranceExpiryDate"
        placeholder={t("insurance_expiry_date")}
        floatLabelType="Always"
        format="dd/MM/yyyy"
        locale="it"
      />
    );
  }
  editTemplateRD(args) {
    const { t } = this.props;
    return (
      <DatePickerComponent
        value={getValue("revisionDate", args)}
        id="revisionDate"
        placeholder={t("revision_date")}
        floatLabelType="Always"
        format="dd/MM/yyyy"
        locale="it"
      />
    );
  }
fuelTypeParams() {
  return {
    params: {
      dataSource: this.fuelTypes,
      fields: { text: 'text', value: 'value' },
      placeholder: 'Seleziona Tipo Carburante',
      allowFiltering: true,
    },
  };
}

  async commandClick(args) {
    
    //console.log(args.target.id, "gridcompOpenGoogleSheet_2_undefined");
    if (args.target.id.startsWith("gridcompOpenGoogleSheet")) {
    //if (args.commandColumn.type === "OpenGoogleSheet") {
      const link = args.rowData.googleSheetLink;
      if (link) {
        window.open(link, '_blank');
        //window.open(link, '_blank', 'noopener,noreferrer,width=800,height=600');
      } else {
        this.setToastInfo("Warning", "Nessun link disponibile");
      }
    }
  }
  render() {
    const { myData, fuelTypeData, isCicass, isAdminOrManager, toastInfo,visibleOnGrid } = this.state;
    const { t } = this.props;
    return (
      <>
        <div style={{ margin: "1%", marginTop: "1%" }}>
          <h2 className="text-gray-800 text-xl font-semibold">{t("AnaTruckPage")}</h2>
        </div>
        <div className="p-4 flex-auto">
          <GridComponent
            id="gridcomp"
            ref={(grid) => (this.gridInstance = grid)}
            dataSource={myData}
            toolbar={toolbarOptions}
            editSettings={isAdminOrManager ? editSettings : editSettingsReduced}
            allowResizing={true}
            allowFiltering={true}
            filterSettings={filterSettings}
            allowPaging={true}
            pageSettings={{ pageSize: 10, pageCount: 5 }}
            allowExcelExport={true}
            actionComplete={this.actionComplete}
            actionBegin={this.actionBegin}
            toolbarClick={this.toolbarClick.bind(this)}
            locale="it"
            allowSorting={true}
            allowRowDragAndDrop={true}
            commandClick={this.commandClick.bind(this)}
          >
            <ColumnsDirective>
            <ColumnDirective
                  field="id"
                  headerText={t("id")}
                  textAlign="Right"
                  isPrimaryKey={true}
                  defaultValue="-1"
                  allowEditing={false}
                  visible={isCicass}
              />
              <ColumnDirective field="name" headerText={t("CriCode")} width="120" validationRules={this.validationRules} />
              <ColumnDirective field="licensePlate" headerText={t("LicensePlate")} width="100" validationRules={this.validationRules} />
              <ColumnDirective field="vehicleType" headerText={t("VehicleType")} width="150" visible={visibleOnGrid}/>
              <ColumnDirective field="brand" headerText={t("Brand")} width="150" />
              <ColumnDirective field="model" headerText={t("Model")} width="150" />
              <ColumnDirective field="registrationYear" headerText={t("RegistrationYear")} textAlign="Right" validationRules={this.validationRules} visible={visibleOnGrid} />
              <ColumnDirective field="chassisNumber" headerText={t("chassisNumber")} visible={visibleOnGrid}/>
              <ColumnDirective field="mileage" headerText={t("Mileage")} validationRules={this.validationRules} textAlign="Right" visible={visibleOnGrid}/>
              <ColumnDirective field="fuelType" headerText={t("FuelType")} 
                               foreignKeyValue="name"
                               foreignKeyField="id"
                               dataSource={fuelTypeData} width="120"
                               validationRules={this.validationRules} />
              <ColumnDirective field="fuelCapacity" headerText={t("fuel_capacity")} textAlign="Right" visible={visibleOnGrid}/>
              <ColumnDirective field="lastMaintenanceDate" headerText={t("last_maintenance_date")} type="date"
                  format={"dd/MM/yyyy"} editTemplate={this.editTemplateLMD.bind(this)} visible={visibleOnGrid}/>
              <ColumnDirective field="nextMaintenanceDate" headerText={t("next_maintenance_date")} type="date"
                  format={"dd/MM/yyyy"} editTemplate={this.editTemplateNMD.bind(this)} visible={visibleOnGrid}/>
              <ColumnDirective field="insuranceDetails" headerText={t("insurance_details")} width="150" visible={visibleOnGrid}/>
              <ColumnDirective field="insuranceExpiryDate" headerText={t("insurance_expiry_date")} type="date"
                  format={"dd/MM/yyyy"} editTemplate={this.editTemplateIED.bind(this)} visible={visibleOnGrid}/>
               <ColumnDirective field="revisionDate" headerText={t("revision_date")} type="date"
                  format={"dd/MM/yyyy"} editTemplate={this.editTemplateRD.bind(this)} visible={visibleOnGrid}/>
              <ColumnDirective field="viewOrder" headerText={t("viewOrder")} width="50" textAlign="Right" visible={isCicass}/>
              <ColumnDirective field="googleSheetLink" headerText={t("link")} visible={visibleOnGrid}/>
              <ColumnDirective field="notes" headerText={t("notes")} width="400" visible={isAdminOrManager} />
              <ColumnDirective headerText={t("operationOnGrid")} width="150" commands={isAdminOrManager ? commands : commandsReduced}></ColumnDirective>
            </ColumnsDirective>
            <Inject
                services={[
                  Resize,
                  Filter,
                  Page,
                  Toolbar,
                  Edit,
                  CommandColumn,
                  ForeignKey,
                  ExcelExport,
                  Sort,
                ]}
              />
          </GridComponent>
          <ToastComponent
            ref={(toast) => { this.toastObj = toast; }}
            id="toast_default"
            position={this.position}
            title={toastInfo.title}
            content={toastInfo.content}
            cssClass={toastInfo.cssClass}
            icon={toastInfo.icon}
          ></ToastComponent>
        </div>
      </>
    );
  }
}

export default withTranslation()(AnaTruckPage);
